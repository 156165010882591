html {
  font-size: var(--base-font-size);
  font-family: var(--font-family);
  color: var(--text-color);
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  margin: 2rem 0 1rem;
  color: var(--headline-color);
  line-height: 1.2;
}

h1, .h1 {font-size: 2em;}
h2, .h2 {font-size: 1.6em;}
h3, .h3 {font-size: 1.5em;}
h4, .h4 {font-size: 1.4em;}
h5, .h5 {font-size: 1.2em;}
h6, .h6 {font-size: 1em;}

a {
  color: var(--link-color);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-decoration-color: var(--link-underline-color);
    -webkit-text-decoration-skip: skip;
    text-decoration-skip: skip;
  }
}

.small {
  font-size: 0.8em;
}

hr {
  border: 0 none;
  border-top: 1px solid var(--shade-30);
}


.calendar-iframe {
  max-width: 100%;
}
