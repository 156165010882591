.field {
  display: block;
  margin-bottom: 1em;
  max-width: 480px;
}

input,
select,
textarea {
  border: var(--field-border);
  padding: 10px;
  width: 100%;
  font-size: var(--field-font-size);
  font-family: var(--font-family);

  &:focus {
    border: var(--field-border-focus);
  }
}
