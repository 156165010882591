[data-pagination] {
  display: flex;
  border-top: 1px solid #rgba(0,0,0,0.05);

  a, span {
    display: block;
    padding: 10px;
  }

.jp-previous { margin-right: 15px; }
.jp-next { margin-left: 15px; }

.jp-disabled, .jp-disabled:hover,
.jp-current, .jp-current:hover  {
	color: #bbb;
  text-decoration: none;
  cursor: default;
}

.jp-current, .jp-current:hover {
	color: var(--red);
	font-weight: bold;
}

span { margin: 0 5px; }

}
