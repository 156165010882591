.l-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1400px;
}

.two-thirds-grid {
  .sidebar {
    background-color: var(--body-bg-shade);
    padding: 10px 20px;
    margin: 0 -20px;
  }
}


@include mq('stacked') {
  .two-thirds-bg {
    background: linear-gradient(to right,  var(--body-bg) 66%, var(--body-bg-shade) 66%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    padding-top: 100px;
    margin-top: -100px;
  }

  .two-thirds-grid {
    display: grid;
    grid-gap: 0;
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "content sidebar";

    .main-content {
      grid-area: content;
      padding-right: 60px;
      padding-bottom: 60px;
      background-color: var(--body-bg);
    }

    .sidebar {
      grid-area: sidebar;
      background-color: var(--body-bg-shade);
      padding: 20px;
      margin: 0;
      padding-top: 120px;
      margin-top: -100px;
    }
  }
}
