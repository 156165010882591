  .fake-google {
    margin-bottom:20px;
    padding: 0;
    background:#fff;
    max-width: 580px;

    &, h3, p, a {
      font-family:'Roboto',arial,sans-serif;
    }

    h3 {
      font-size:20px;
      margin-top:0;
      margin-bottom:0;
      line-height: 1.3;
      text-transform: none;
      font-weight: normal;
      letter-spacing: normal;

      a {
        color: #1a0dab;
        text-decoration:none;

        &:hover{
          color: #1a0dab;
          text-decoration:underline;
        }
      }
    }

    p {color: #545454;font-size:14px;margin:0;line-height: 1.57;}

    .link {color: #006621;font-size:16px;margin:0;}
  }
  .fake-facebook {border: 1px solid #d1d1d1;background:#fff;margin-bottom:20px;max-width: 480px;}
  .fake-facebook img { border-bottom: 1px solid #d1d1d1;display:block; }
  .fake-facebook .wrap { padding: 10px;}
  .fake-facebook h3 {font-family:Georgia, serif;font-size:18px;line-height: 1.3;margin: 0;font-weight:normal;letter-spacing: normal;text-transform: none;}
  .fake-facebook h3 a {color: #1d2129;text-decoration:none;}
  .fake-facebook h3 a:hover {text-decoration: underline;color: #1d2129;}
  .fake-facebook .desc {color: #4b4f56;font-family: Helvetica, Arial, sans-serif;line-height: 16px;font-size:12px;}
  .fake-facebook .link {font-size:11px;line-height:11px;color:#90949c;font-family: Helvetica, Arial, sans-serif;text-transform:uppercase;}


.social-preview--label {
  text-transform: uppercase;
  opacity: 0.6;
  font-size: 14px;
  font-family:'Roboto',arial,sans-serif;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  margin: 2em 0 1em 0;
}
