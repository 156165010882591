.masthead {
  --masthead-height: 40px;
  padding-top: calc(var(--masthead-height) + 20px);
  padding-bottom: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  -webkit-mask-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400.3 778.4"><path d="M0 771.2s80.3-16.1 200.2 0c119.9 16.1 200.2 0 200.2 0V0H0v771.2z"/></svg>');
  -webkit-mask-size: 100%;
  -webkit-mask-position: bottom;
  mask-image: svg-url('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400.3 778.4"><path d="M0 771.2s80.3-16.1 200.2 0c119.9 16.1 200.2 0 200.2 0V0H0v771.2z"/></svg>');
  mask-size: 100%;
  mask-position: bottom;

  @at-root .homepage & {
    min-height: 60vh;
  }

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom right, var(--blue), var(--red));
    content: "";
    z-index: 2;
    mix-blend-mode: hard-light;
  }

  &-text {
    position: relative;
    z-index: 10;
    width: 100%;
    margin-bottom: 60px;
    margin-top: 60px;

    &, h1, h2, h3, h4, h5, h6, a {
      color: var(--knockout-color);
    }

    & *:first-child {
      margin-top: 0;
    }

    & *:last-child {
      margin-bottom: 0;
    }

    @at-root .homepage & {
      text-align: center;
    }
  }

  &-img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  @include mq('stacked') {
    --masthead-height: 100px;
  }
}

.kb-slideshow {
  position:absolute;
  width:100%;
  height:100%;
  overflow:hidden;
  top: 0;
  opacity: 0.5;

  .masthead-img {
    opacity:0;
    transition-property: opacity, transform;
    transition-duration: 3s, 10s;
    transform-origin: bottom left;

    &:nth-child(2n+1) {
      transform-origin: top right;
    }

    &:nth-child(3n+1) {
      transform-origin: top left;
    }

    &:nth-child(4n+1) {
      transform-origin: bottom right;
    }

    &.fx {
      opacity:1;
      transform: scale(1.1);

      &:first-child + img ~ img  {
        z-index:-1;
      }
    }
  }
}


.fb-video {
  iframe {
    max-width: 100% !important;
  }
}
