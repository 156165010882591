$breakpoints: (
  "stacked": (
    min-width: 600px,
  ),
  "skinny": (
    min-width: 767px,
  ),
  "nav-break": (
    min-width: 767px,
  ),
  "medium": (
    min-width: 992px,
  ),
  "large": (
    min-width: 1200px,
  ),
) !default;

@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @warn "No value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

// Function to create an optimized svg url
// Source: http://codepen.io/jakob-e/pen/doMoML
// Explained: https://codepen.io/tigt/post/optimizing-svgs-in-data-uris
@function svg-url($svg) {
  //  Chunk up string in order to avoid
  //  "stack level too deep" error
  $encoded: "";
  $slice: 2000;
  $index: 0;
  $loops: ceil(calc(str-length($svg) / $slice));

  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    // Encode (may need a few extra replacements)
    $chunk: str-replace($chunk, '"', "'");
    $chunk: str-replace($chunk, "<", "%3C");
    $chunk: str-replace($chunk, ">", "%3E");
    $chunk: str-replace($chunk, "&", "%26");
    $chunk: str-replace($chunk, "#", "%23");
    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }

  @return url("data:image/svg+xml;charset=utf8,#{$encoded}");
}

//  Helper function to replace characters in a string
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }
  @return $string;
}
