.thumbs {
  list-style: none;
  margin: 1em 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  > li {
    width: calc(33% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;

    img {
      display: block;
    }

    p {
      font-size: 0.8em;
      text-align: center;
      font-style: italic;
    }
  }
}
