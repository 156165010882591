:root {

  --gold-vals: 254, 215, 102;
  --blue-vals: 28, 68, 142;
  --shade-vals: 43, 48, 58;
  --green-vals: 6, 167, 125;
  --red-vals: 237, 37, 78;


  --gold: rgba(var(--gold-vals), 1);
  --blue: hsla(203, 100%, 50%, 1);
  --blue-shade: hsla(203, 100%, 40%, 1);
  --blue-shade-2: hsla(203, 100%, 30%, 0.5);
  --blue-tint: hsla(203, 100%, 70%, 1);
  --blue-tint2: hsla(203, 100%, 97%, 1);
  --green: rgba(var(--green-vals), 1);
  --red: hsla(351, 83%, 55%, 1);
  --shade: rgba(var(--shade-vals), 1);
  --tint: white;

  --shade-30: rgba(var(--shade-vals), 0.3);
  --shade-50: rgba(var(--shade-vals), 0.5);
  --blue-80: rgba(var(--green-vals), 0.8);

  --body-bg: var(--tint);
  --body-bg-shade: var(--blue-tint2);

  --font-family: 'Cardo', serif;
  --base-font-size: calc(112.5% + 0.25vw);

  --text-color: var(--shade);
  --knockout-color: var(--tint);
  --headline-color: var(--blue-shade);
  --accent-color: #f0f0f0;
  --link-color: var(--blue-shade);
  --link-underline-color: var(--blue-tint);

  --field-border: 1px solid var(--shade-30);
  --field-border-focus: 1px solid var(--blue);
  --field-font-size: 1em;

  --global-margin: 1rem;
  --global-radius: .25rem;

  --button-bg: var(--link-color);
  --button-border: var(--global-radius);
  --button-radius: var(--global-radius);
  --button-color: var(--knockout-color);
  --button-font-size: 1em;
  --button-font-family: var(--font-family);

  --dropdown-bg: var(--blue-shade-2);
  --nav-text-color: var(--tint);
}
