html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

.wa-mediabox-frame .wa-mediabox-close:focus, .wa-mediabox-next:focus, .wa-mediabox-prev:focus {
  outline: 0;
}

.wa-mediabox-overlay {
  width: 100%;
  height: 100%;
  z-index: 1000;
  opacity: 0;
  background: #141414f2;
  margin: 0;
  padding: 0;
  transition: opacity .4s;
  position: fixed;
  top: 0;
  left: 0;
}

.wa-mediabox-overlay.opened {
  opacity: 1;
}

.wa-mediabox-frame {
  width: 0;
  height: 0;
  background: #fff;
  margin-top: 0;
  margin-left: 0;
  transition: width .5s, height .5s, margin .5s;
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 3px 14px #00000080;
}

.wa-mediabox-container {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 0;
  cursor: pointer;
  transition: opacity .3s;
  position: relative;
  overflow: hidden;
}

.wa-mediabox-frame.loaded .wa-mediabox-container {
  opacity: 1;
}

.wa-mediabox-container img {
  max-width: 100%;
  cursor: pointer;
}

.wa-mediabox-frame .wa-mediabox-close {
  width: 36px;
  height: 36px;
  cursor: pointer;
  background: #444;
  border: 0;
  border-radius: 36px;
  padding: 6px;
  transition: background .2s, box-shadow .2s;
  position: absolute;
  top: -18px;
  right: -18px;
  box-shadow: 0 2px 8px #0003;
}

.wa-mediabox-frame .wa-mediabox-close:hover {
  background: #d32f2f;
  box-shadow: 0 4px 12px #0006;
}

.wa-mediabox-frame .wa-mediabox-close svg {
  width: 24px;
  height: 24px;
  fill: #aaa;
  transition: fill .2s;
}

.wa-mediabox-frame .wa-mediabox-close:hover svg {
  fill: #fff;
}

.wa-mediabox-title {
  color: #fff;
  opacity: 0;
  background: #000c;
  padding: 0 52px 0 14px;
  font-size: 13px;
  font-weight: 300;
  line-height: 24px;
  transition: opacity .3s, padding .3s;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.wa-mediabox-frame.loaded.has-title .wa-mediabox-title {
  opacity: 1;
  padding: 14px 52px 14px 14px;
}

.wa-mediabox-frame .wa-mediabox-open {
  opacity: 0;
  z-index: 20;
  cursor: pointer;
  background: #fff;
  border: 0;
  border-radius: 2px;
  padding: 4px;
  transition: opacity .3s;
  display: none;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.wa-mediabox-frame .wa-mediabox-open svg {
  width: 24px;
  height: 24px;
  fill: #333;
}

.wa-mediabox-frame.can-open-in-new .wa-mediabox-open {
  display: block;
}

.wa-mediabox-frame.can-open-in-new:hover .wa-mediabox-open {
  opacity: .4;
}

.wa-mediabox-frame.can-open-in-new:hover .wa-mediabox-open:hover {
  opacity: 1;
}

.wa-mediabox-next, .wa-mediabox-prev {
  width: 74px;
  height: 74px;
  cursor: pointer;
  opacity: 0;
  background: none;
  border: 0;
  margin-top: -32px;
  padding: 5px;
  transition: opacity .3s;
  position: absolute;
  top: 50%;
}

.wa-mediabox-prev {
  left: -80px;
}

.wa-mediabox-next {
  right: -80px;
}

.wa-mediabox-frame.has-next .wa-mediabox-next, .wa-mediabox-frame.has-prev .wa-mediabox-prev {
  opacity: 1;
}

.wa-mediabox-next svg, .wa-mediabox-prev svg {
  width: 64px;
  height: 64px;
  fill: #999;
  transition: fill .2s;
}

.wa-mediabox-next:hover svg, .wa-mediabox-prev:hover svg {
  fill: #fff;
}

@media (max-width: 479px) {
  .wa-mediabox-prev {
    left: -40px;
  }

  .wa-mediabox-next {
    right: -40px;
  }

  .wa-mediabox-next, .wa-mediabox-prev {
    width: 42px;
    height: 42px;
    margin-top: -21px;
  }

  .wa-mediabox-next svg, .wa-mediabox-prev svg {
    width: 32px;
    height: 32px;
  }
}

.wa-mediabox-preloader {
  width: 64px;
  height: 64px;
  opacity: 1;
  margin-top: -32px;
  margin-left: -32px;
  transition: opacity .5s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.wa-mediabox-preloader.hidden {
  opacity: 0;
}

.wa-mediabox-preloader .wa-mediabox-preloader-wrap {
  box-sizing: border-box;
  width: 64px;
  height: 64px;
  animation: 1.568s linear infinite wa-mediabox-preloader-container-rotate;
  display: inline-block;
  position: relative;
}

.wa-mediabox-preloader .wa-mediabox-preloader-spinner {
  width: 100%;
  height: 100%;
  opacity: 1;
  box-sizing: inherit;
  border-color: #2196f3;
  animation: 5.332s cubic-bezier(.4, 0, .2, 1) infinite both wa-mediabox-preloader-fill-unfill-rotate;
  position: absolute;
}

.wa-mediabox-preloader .wa-mediabox-preloader-clipper {
  width: 50%;
  height: 100%;
  border-color: inherit;
  box-sizing: inherit;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.wa-mediabox-preloader .wa-mediabox-preloader-clipper.left {
  float: left;
}

.wa-mediabox-preloader .wa-mediabox-preloader-clipper.right {
  float: right;
}

.wa-mediabox-preloader .wa-mediabox-preloader-clipper .wa-mediabox-preloader-circle {
  width: 200%;
  height: 100%;
  border-style: solid;
  border-width: 5px;
  border-color: inherit;
  box-sizing: inherit;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border-bottom-color: #0000 !important;
}

.wa-mediabox-preloader .wa-mediabox-preloader-clipper.left .wa-mediabox-preloader-circle {
  animation: 1.333s cubic-bezier(.4, 0, .2, 1) infinite both wa-mediabox-preloader-left-spin;
  left: 0;
  transform: rotate(129deg);
  border-right-color: #0000 !important;
}

.wa-mediabox-preloader .wa-mediabox-preloader-clipper.right .wa-mediabox-preloader-circle {
  animation: 1.333s cubic-bezier(.4, 0, .2, 1) infinite both wa-mediabox-preloader-right-spin;
  left: -100%;
  transform: rotate(-129deg);
  border-left-color: #0000 !important;
}

.wa-mediabox-preloader .wa-mediabox-preloader-patch {
  width: 10%;
  height: 100%;
  border-color: inherit;
  position: absolute;
  top: 0;
  left: 45%;
  overflow: hidden;
}

.wa-mediabox-preloader .wa-mediabox-preloader-patch .wa-mediabox-preloader-circle {
  width: 1000%;
  left: -450%;
}

@-webkit-keyframes wa-mediabox-preloader-container-rotate {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes wa-mediabox-preloader-container-rotate {
  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes wa-mediabox-preloader-fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
  }

  25% {
    -webkit-transform: rotate(270deg);
  }

  37.5% {
    -webkit-transform: rotate(405deg);
  }

  50% {
    -webkit-transform: rotate(540deg);
  }

  62.5% {
    -webkit-transform: rotate(675deg);
  }

  75% {
    -webkit-transform: rotate(810deg);
  }

  87.5% {
    -webkit-transform: rotate(945deg);
  }

  to {
    -webkit-transform: rotate(1080deg);
  }
}

@keyframes wa-mediabox-preloader-fill-unfill-rotate {
  12.5% {
    transform: rotate(135deg);
  }

  25% {
    transform: rotate(270deg);
  }

  37.5% {
    transform: rotate(405deg);
  }

  50% {
    transform: rotate(540deg);
  }

  62.5% {
    transform: rotate(675deg);
  }

  75% {
    transform: rotate(810deg);
  }

  87.5% {
    transform: rotate(945deg);
  }

  to {
    transform: rotate(1080deg);
  }
}

@keyframes wa-mediabox-preloader-blue-fade-in-out {
  100%, 25%, 90%, from {
    opacity: 1;
  }

  26%, 89% {
    opacity: 0;
  }
}

@-webkit-keyframes wa-mediabox-preloader-left-spin {
  from, to {
    -webkit-transform: rotate(130deg);
  }

  50% {
    -webkit-transform: rotate(-5deg);
  }
}

@keyframes wa-mediabox-preloader-left-spin {
  from, to {
    transform: rotate(130deg);
  }

  50% {
    transform: rotate(-5deg);
  }
}

@-webkit-keyframes wa-mediabox-preloader-right-spin {
  from, to {
    -webkit-transform: rotate(-130deg);
  }

  50% {
    -webkit-transform: rotate(5deg);
  }
}

@keyframes wa-mediabox-preloader-right-spin {
  from, to {
    transform: rotate(-130deg);
  }

  50% {
    transform: rotate(5deg);
  }
}

html, body {
  margin: 0;
  padding: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

:root {
  --gold-vals: 254, 215, 102;
  --blue-vals: 28, 68, 142;
  --shade-vals: 43, 48, 58;
  --green-vals: 6, 167, 125;
  --red-vals: 237, 37, 78;
  --gold: rgba(var(--gold-vals), 1);
  --blue: #009dff;
  --blue-shade: #007ecc;
  --blue-shade-2: #005e9980;
  --blue-tint: #66c4ff;
  --blue-tint2: #f0f9ff;
  --green: rgba(var(--green-vals), 1);
  --red: #eb2d4a;
  --shade: rgba(var(--shade-vals), 1);
  --tint: white;
  --shade-30: rgba(var(--shade-vals), .3);
  --shade-50: rgba(var(--shade-vals), .5);
  --blue-80: rgba(var(--green-vals), .8);
  --body-bg: var(--tint);
  --body-bg-shade: var(--blue-tint2);
  --font-family: "Cardo", serif;
  --base-font-size: calc(112.5% + .25vw);
  --text-color: var(--shade);
  --knockout-color: var(--tint);
  --headline-color: var(--blue-shade);
  --accent-color: #f0f0f0;
  --link-color: var(--blue-shade);
  --link-underline-color: var(--blue-tint);
  --field-border: 1px solid var(--shade-30);
  --field-border-focus: 1px solid var(--blue);
  --field-font-size: 1em;
  --global-margin: 1rem;
  --global-radius: .25rem;
  --button-bg: var(--link-color);
  --button-border: var(--global-radius);
  --button-radius: var(--global-radius);
  --button-color: var(--knockout-color);
  --button-font-size: 1em;
  --button-font-family: var(--font-family);
  --dropdown-bg: var(--blue-shade-2);
  --nav-text-color: var(--tint);
}

html {
  font-size: var(--base-font-size);
  font-family: var(--font-family);
  color: var(--text-color);
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--headline-color);
  margin: 2rem 0 1rem;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2em;
}

h2, .h2 {
  font-size: 1.6em;
}

h3, .h3 {
  font-size: 1.5em;
}

h4, .h4 {
  font-size: 1.4em;
}

h5, .h5 {
  font-size: 1.2em;
}

h6, .h6 {
  font-size: 1em;
}

a {
  color: var(--link-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
  text-decoration-color: var(--link-underline-color);
  -webkit-text-decoration-skip: skip;
  text-decoration-skip: skip;
}

.small {
  font-size: .8em;
}

hr {
  border: 0;
  border-top: 1px solid var(--shade-30);
}

.calendar-iframe {
  max-width: 100%;
}

.button {
  background: var(--button-bg);
  border: var(--button-border);
  border-radius: var(--button-radius);
  color: var(--button-color);
  font-size: var(--button-font-size);
  font-family: var(--button-font-family);
  cursor: pointer;
  padding: .25em 1em;
}

.button:hover {
  background: linear-gradient(to top, #00000040, #00000040) var(--button-bg);
}

.field {
  max-width: 480px;
  margin-bottom: 1em;
  display: block;
}

input, select, textarea {
  border: var(--field-border);
  width: 100%;
  font-size: var(--field-font-size);
  font-family: var(--font-family);
  padding: 10px;
}

input:focus, select:focus, textarea:focus {
  border: var(--field-border-focus);
}

.site-header {
  padding: var(--global-margin) 0;
  z-index: 10;
  background: none;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.site-logo {
  padding: 10px;
  font-size: .8em;
}

.site-logo img {
  width: 120px;
  filter: drop-shadow(0 0 3px #000c);
  margin-left: 10px;
  display: block;
}

.nav-toggle {
  appearance: none;
  color: var(--tint);
  width: 24px;
  height: 24px;
  margin: 0 0 0 20px;
  display: block;
}

.site-nav {
  justify-content: space-between;
  align-items: center;
  display: none;
}

.site-nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.site-nav-item {
  position: relative;
}

.site-nav-item.has-dropdown:hover {
  background: var(--dropdown-bg);
  border-radius: 4px 4px 0 0;
}

.site-nav-item > ul {
  background: var(--dropdown-bg);
  width: 200px;
  border-radius: 0 4px 4px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
}

.site-nav-item > ul a {
  border-radius: 0;
  padding: 6px 20px;
  font-size: .8em;
  display: block;
}

.site-nav-item > ul li:first-child a {
  border-radius: 4px 4px 0 0;
}

.site-nav-item > ul li:last-child a {
  border-radius: 0 0 4px 4px;
}

.site-nav-item:hover > ul, .site-nav-item:focus-within > ul {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
}

.site-nav-link {
  padding: 10px 20px;
  display: block;
}

.site-nav-link:hover {
  background-color: #0000;
}

.site-nav a {
  color: var(--nav-text-color);
  text-shadow: 0 0 3px #000c;
  border-radius: 4px;
  text-decoration: none;
}

.site-nav a:hover {
  background: var(--blue-shade-2);
  text-decoration: none;
}

@media (min-width: 767px) {
  .site-nav {
    display: flex;
  }

  .nav-toggle {
    display: none;
  }

  .site-logo img {
    width: 240px;
  }
}

.l-footer {
  border-top: 1px solid var(--shade-30);
  margin-top: 4em;
}

.l-container {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: block;
}

.two-thirds-grid .sidebar {
  background-color: var(--body-bg-shade);
  margin: 0 -20px;
  padding: 10px 20px;
}

@media (min-width: 600px) {
  .two-thirds-bg {
    background: linear-gradient(to right, var(--body-bg) 66%, var(--body-bg-shade) 66%);
    margin-top: -100px;
    padding-top: 100px;
  }

  .two-thirds-grid {
    grid-gap: 0;
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "content sidebar";
    display: grid;
  }

  .two-thirds-grid .main-content {
    background-color: var(--body-bg);
    grid-area: content;
    padding-bottom: 60px;
    padding-right: 60px;
  }

  .two-thirds-grid .sidebar {
    background-color: var(--body-bg-shade);
    grid-area: sidebar;
    margin: -100px 0 0;
    padding: 120px 20px 20px;
  }
}

.masthead {
  --masthead-height: 40px;
  padding-top: calc(var(--masthead-height)  + 20px);
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  display: flex;
  position: relative;
  -webkit-mask-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 400.3 778.4'%3E%3Cpath d='M0 771.2s80.3-16.1 200.2 0c119.9 16.1 200.2 0 200.2 0V0H0v771.2z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 400.3 778.4'%3E%3Cpath d='M0 771.2s80.3-16.1 200.2 0c119.9 16.1 200.2 0 200.2 0V0H0v771.2z'/%3E%3C/svg%3E");
  -webkit-mask-position: bottom;
  mask-position: bottom;
  -webkit-mask-size: 100%;
  mask-size: 100%;
}

.homepage .masthead {
  min-height: 60vh;
}

.masthead:after {
  background: linear-gradient(to bottom right, var(--blue), var(--red));
  content: "";
  z-index: 2;
  mix-blend-mode: hard-light;
  position: absolute;
  inset: 0;
}

.masthead-text {
  z-index: 10;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
  position: relative;
}

.masthead-text, .masthead-text h1, .masthead-text h2, .masthead-text h3, .masthead-text h4, .masthead-text h5, .masthead-text h6, .masthead-text a {
  color: var(--knockout-color);
}

.masthead-text :first-child {
  margin-top: 0;
}

.masthead-text :last-child {
  margin-bottom: 0;
}

.homepage .masthead-text {
  text-align: center;
}

.masthead-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  position: absolute;
  top: 0;
}

@media (min-width: 600px) {
  .masthead {
    --masthead-height: 100px;
  }
}

.kb-slideshow {
  width: 100%;
  height: 100%;
  opacity: .5;
  position: absolute;
  top: 0;
  overflow: hidden;
}

.kb-slideshow .masthead-img {
  opacity: 0;
  transform-origin: 0 100%;
  transition-property: opacity, transform;
  transition-duration: 3s, 10s;
}

.kb-slideshow .masthead-img:nth-child(2n+1) {
  transform-origin: 100% 0;
}

.kb-slideshow .masthead-img:nth-child(3n+1) {
  transform-origin: 0 0;
}

.kb-slideshow .masthead-img:nth-child(4n+1) {
  transform-origin: 100% 100%;
}

.kb-slideshow .masthead-img.fx {
  opacity: 1;
  transform: scale(1.1);
}

.kb-slideshow .masthead-img.fx:first-child + img ~ img {
  z-index: -1;
}

.fb-video iframe {
  max-width: 100% !important;
}

.open-panel {
  z-index: 100;
  text-transform: uppercase;
  color: var(--blue);
  border-radius: 4px;
  margin: 10px 0;
  padding: .4px;
  font-size: 18px;
  line-height: 1;
  text-decoration: none;
  position: absolute;
  top: .4em;
  left: 1em;
}

.open-panel:hover, .open-panel:focus {
  color: tint(var(--blue), 20%);
  text-decoration: none;
}

.open-panel:hover svg path, .open-panel:focus svg path {
  fill: tint(var(--blue), 20%);
}

.open-panel svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 4px;
}

.open-panel svg path {
  fill: var(--blue);
}

.open-panel span {
  vertical-align: middle;
  display: inline-block;
}

@media (min-width: 600px) {
  .open-panel, .close-panel, .open-panel-wrap {
    display: none;
  }
}

.close-panel {
  color: var(--tint);
  margin-top: .5em;
  margin-left: 1em;
  font-size: 36px;
  text-decoration: none;
  position: relative;
}

.close-panel:hover, .close-panel:focus {
  color: shade(var(--tint), 20%);
  text-decoration: none;
}

.mobile-nav {
  width: 85%;
  height: 100%;
  text-align: left;
  background: var(--blue);
  margin-top: 0;
  padding: 0 20px;
  display: block;
  position: absolute;
  top: 0;
  left: -85%;
}

@media (min-width: 600px) {
  .mobile-nav {
    display: none;
  }
}

.top {
  text-align: right;
  padding: 10px;
}

.nav-is-open {
  width: 100%;
  overflow-x: hidden;
}

.nav-is-open .open-panel {
  visibility: hidden;
}

.nav-is-open .open-panel-wrap {
  opacity: .5;
}

.nav-is-open .off-canvas-wrap {
  transition: transform .3s;
  left: 0;
  transform: translate3d(85%, 0, 0);
}

.nav-is-open .off-canvas-content-wrap {
  opacity: .6;
}

.off-canvas-wrap {
  transition: transform .2s;
  left: 0;
}

.sf-menu {
  display: none;
}

@media (min-width: 600px) {
  .sf-menu {
    display: block;
  }
}

.mobile-nav > ul {
  text-align: left;
  background: none;
  margin: 0;
  padding: 0;
  font-size: 22px;
  font-weight: 300;
  list-style: none;
}

.mobile-nav > ul ul {
  margin: 0;
  padding: 0;
  display: none;
}

.mobile-nav > ul li {
  display: block;
}

.mobile-nav > ul li a {
  color: #fff;
  border-top: 0 solid #ffffff4d;
  border-bottom: 0 solid #0000001a;
  padding: 8px 10px;
  font-size: 18px;
  text-decoration: none;
  display: block;
}

.mobile-nav > ul li li a {
  padding: 4px 10px 4px 30px;
  font-size: 18px;
}

.mobile-nav > ul li li li a {
  padding: 4px 10px 4px 60px;
}

.mobile-nav > ul li.has-child > a:after {
  content: "";
  float: right;
  color: var(--tint);
  width: 0;
  height: 0;
  border-top: 5px solid #0000;
  border-bottom: 5px solid #0000;
  border-left: 5px solid #fff;
  margin: 4px 0 0;
  display: block;
}

.mobile-nav > ul li.has-child.down {
  color: red;
}

.mobile-nav > ul li.has-child.down > ul {
  display: block;
}

.mobile-nav > ul li.has-child.down > a:after {
  border-top: 5px solid #fff;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
}

.mobile-nav > ul li.has-child a.open-subs {
  opacity: .6;
}

.mobile-nav > ul li.prepended > a {
  font-weight: bold;
}

.mobile-nav .nav-append {
  padding: 30px 10px;
}

.mobile-nav .nav-append .lang {
  margin: 0 0 20px;
  display: block;
}

.thumbs {
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 1em 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.thumbs > li {
  width: calc(33% - 20px);
  margin-bottom: 20px;
  margin-right: 20px;
}

.thumbs > li img {
  display: block;
}

.thumbs > li p {
  text-align: center;
  font-size: .8em;
  font-style: italic;
}

[data-pagination] {
  border-top: 1px solid #rgba 0, 0, 0, .05;
  display: flex;
}

[data-pagination] a, [data-pagination] span {
  padding: 10px;
  display: block;
}

[data-pagination] .jp-previous {
  margin-right: 15px;
}

[data-pagination] .jp-next {
  margin-left: 15px;
}

[data-pagination] .jp-disabled, [data-pagination] .jp-disabled:hover, [data-pagination] .jp-current, [data-pagination] .jp-current:hover {
  color: #bbb;
  cursor: default;
  text-decoration: none;
}

[data-pagination] .jp-current, [data-pagination] .jp-current:hover {
  color: var(--red);
  font-weight: bold;
}

[data-pagination] span {
  margin: 0 5px;
}

.fake-google {
  max-width: 580px;
  background: #fff;
  margin-bottom: 20px;
  padding: 0;
}

.fake-google, .fake-google h3, .fake-google p, .fake-google a {
  font-family: Roboto, arial, sans-serif;
}

.fake-google h3 {
  text-transform: none;
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.3;
}

.fake-google h3 a {
  color: #1a0dab;
  text-decoration: none;
}

.fake-google h3 a:hover {
  color: #1a0dab;
  text-decoration: underline;
}

.fake-google p {
  color: #545454;
  margin: 0;
  font-size: 14px;
  line-height: 1.57;
}

.fake-google .link {
  color: #006621;
  margin: 0;
  font-size: 16px;
}

.fake-facebook {
  max-width: 480px;
  background: #fff;
  border: 1px solid #d1d1d1;
  margin-bottom: 20px;
}

.fake-facebook img {
  border-bottom: 1px solid #d1d1d1;
  display: block;
}

.fake-facebook .wrap {
  padding: 10px;
}

.fake-facebook h3 {
  letter-spacing: normal;
  text-transform: none;
  margin: 0;
  font-family: Georgia, serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.3;
}

.fake-facebook h3 a {
  color: #1d2129;
  text-decoration: none;
}

.fake-facebook h3 a:hover {
  color: #1d2129;
  text-decoration: underline;
}

.fake-facebook .desc {
  color: #4b4f56;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 16px;
}

.fake-facebook .link {
  color: #90949c;
  text-transform: uppercase;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 11px;
}

.social-preview--label {
  text-transform: uppercase;
  opacity: .6;
  border-bottom: 1px solid #0003;
  margin: 2em 0 1em;
  font-family: Roboto, arial, sans-serif;
  font-size: 14px;
}

/*# sourceMappingURL=styles.css.map */
