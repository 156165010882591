.button {
  background: var(--button-bg);
  border: var(--button-border);
  border-radius: var(--button-radius);
  color: var(--button-color);
  padding: .25em 1em;
  font-size: var(--button-font-size);
  font-family: var(--button-font-family);
  cursor: pointer;

  &:hover {
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.25),
      rgba(0, 0, 0, 0.25)
    )
    var(--button-bg);
  }
}
