.site-header {
  background: transparent;
  padding: var(--global-margin) 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-logo {
  padding: 10px;
  font-size: 0.8em;

  img {
    display: block;
    width: 120px;
    margin-left: 10px;
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.8));
  }
}

.nav-toggle {
  appearance: none;
  -webkit-appearance: none;
  display: block;
  margin: 0 0 0 20px;
  color: var(--tint);
  width: 24px;
  height: 24px;
}

.site-nav {
  display: none;
  justify-content: space-between;
  align-items: center;

  &-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &-item {
    position: relative;

    &.has-dropdown:hover {
      border-radius: 4px 4px 0 0;
      background: var(--dropdown-bg);
    }

    > ul {
      display: none;
      list-style: none;
      padding: 0;
      margin: 0;
      background: var(--dropdown-bg);
      width: 200px;
      border-radius: 0 4px 4px 4px;

      a {
        display: block;
        padding: 6px 20px;
        border-radius: 0;
        font-size: 0.8em;
      }

      li:first-child a {
        border-radius: 4px 4px 0 0;
      }

      li:last-child a {
        border-radius: 0 0 4px 4px;
      }
    }

    &:hover > ul,
    &:focus-within > ul {
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
    }
  }

  // first tier nav links
  &-link {
    display: block;
    padding: 10px 20px;

    &:hover {
      background-color: transparent;
    }
  }

  // all nav links
  a {
    color: var(--nav-text-color);
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
    text-decoration: none;
    border-radius: 4px;

    &:hover {
      text-decoration: none;
      background: var(--blue-shade-2);
    }
  }
}

@include mq('nav-break') {
  .site-nav {
    display: flex;
  }

  .nav-toggle {
    display: none;
  }

  .site-logo img {
    width: 240px;
  }

}
